<template>
  <div id="content">
    <div class="flex flex-col px-4 py-4 gap-y-10 pb-10">
      <div class="flex flex-col max-w-3xl gap-y-4 px-4 py-4">
          <h1 class="text-center font-bold text-4xl">NOVIDADES</h1>
          <div class="flex flex-col gap-y-2 px-4 bg-white shadow-md py-4 rounded-lg">
              <div class="flex flex-row justify-between px-4">
                <span class="text-2xl font-semibold">> Atualização CataCloud 1.0.0<strong class="text-yellow font-mono text-xl ml-4">NOVO!</strong></span>
              </div>
              <hr/>
              <div class="ml-4 flex flex-col gap-y-2 text-base">
                  <li>Adicionado seção de Registros para monitoramento dos cofres <strong>FIT</strong>, nesta seção você pode consultar avisos e erros críticos do cofre, como por exemplo armazenamento, uso do validador e acompanhamento diário.</li>
                  <li>Adicionado seção <strong>CataMoeda</strong> para acompanhamento técnico do produto <strong>CataMoeda</strong>, nesta seção você pode consultar quando o produto está com pouco papel na impressora, e pode consultar falhas da máquina, serializador, auto-diagnóstico e afins...</li>
                  <li>Corrigido problema de paginação das seções de produtos e gerenciamentos aonde o usuário visualizador apenas conseguia ver a primeira página da tabela.</li>
                  <li>Alterado limite de linhas de tabela de 10 para 100 linhas quando o usuário filtra por data, este recurso ajudará o usuário a fazer relatórios financeiros diários de seus equipamentos.</li>
              </div>
              <div class="flex flex-row justify-between px-4 mt-4 self-end">
                <small>Postado por <strong>Guilherme Martins</strong> no dia 18/10/2023.</small>
              </div>
          </div>
      </div>
    </div>

    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base vs-con-loading__container"
        id="usersCard"
        @click="goto('users')"
        v-if="$store.state.AppActiveUser.type > 0"
      >
        <statistics-card-line
          hideChart
          class="mb-base link"
          icon="UsersIcon"
          :statistic="userCount"
          :statisticTitle="$t('user.users')"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base vs-con-loading__container"
        id="companiesCard"
        @click="goto('company')"
        v-if="$store.state.AppActiveUser.type > 0"
      >
        <statistics-card-line
          hideChart
          class="mb-base link"
          icon="BriefcaseIcon"
          :statistic="companyCount"
          color="warning"
          :statisticTitle="$t('company.companies')"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base vs-con-loading__container"
        id="machinesCard"
        @click="goto('machine')"
      >
        <statistics-card-line
          hideChart
          class="mb-base link"
          icon="PackageIcon"
          :statistic="machineCount"
          color="success"
          :statisticTitle="$t('machine.machines')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
export default {
  components: {
    StatisticsCardLine
  },
  data() {
    return {
      version: {},
      machineCount: "",
      userCount: "",
      companyCount: ""
    };
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    loadData() {
      let self = this;

      this.$vs.loading({
        container: "#machinesCard",
        scale: 0.5
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "machine/all" })
        .then(data => {
          self.$vs.loading.close("#machinesCard > .con-vs-loading");
          self.machineCount = data.filter(x => x.active).length;
        });

      this.$vs.loading({
        container: "#usersCard",
        scale: 0.5
      });

      if (this.$store.state.AppActiveUser.type > 0) {
        this.$store
          .dispatch("httpRequest", { method: "get", url: "user/all" })
          .then(data => {
            self.$vs.loading.close("#usersCard > .con-vs-loading");
            self.userCount = data.filter(x => x.active).length;
          });

        this.$vs.loading({
          container: "#companiesCard",
          scale: 0.5
        });

        this.$store
          .dispatch("httpRequest", { method: "get", url: "company/all" })
          .then(data => {
            self.$vs.loading.close("#companiesCard > .con-vs-loading");
            self.companyCount = data.filter(x => x.active).length;
          });
      }
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
}
</style>
